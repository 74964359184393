import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../i18n';
import Header from "components/headers/lp-header-top";
import Top from "components/hero/lp-top";
import TopMessage from "components/features/lp-top-message";
import Features from "components/features/lp-body";
import Footer from "components/footers/lp-footer";
import TopNotice from "components/features/lp-top-notice";

const ConotyLp = () => {
  const { i18n } = useTranslation();
  const currentLanguage = useLanguage();
  const [forceRender, setForceRender] = useState(0);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('userLanguage');
    if (savedLanguage && i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage).then(() => {
        // 言語変更後に強制的に再レンダリング
        setForceRender(prev => prev + 1);
      });
    }
    
    if (process.env.NODE_ENV === 'development') {
      console.log('Language changed in ConotyLp:', currentLanguage);
      console.log('i18n current language:', i18n.language);
      console.log('Saved language:', savedLanguage);
    }

    // コンポーネントのマウント時とアンマウント時のログ
    return () => {
      if (process.env.NODE_ENV === 'development') {
        console.log('ConotyLp unmounting with language:', i18n.language);
      }
    };
  }, [currentLanguage, i18n]);

  return (
    <div key={`page-${currentLanguage}-${forceRender}`}>
      <Header key={`header-${currentLanguage}-${forceRender}`} />
      <Top key={`top-${currentLanguage}-${forceRender}`} />
      <TopMessage key={`top-message-${currentLanguage}-${forceRender}`} />
      <TopNotice key={`top-notice-${currentLanguage}-${forceRender}`} />
      <Features key={`features-${currentLanguage}-${forceRender}`} />
      <Footer key={`footer-${currentLanguage}-${forceRender}`} />
    </div>
  );
};

export default React.memo(ConotyLp, (prevProps, nextProps) => {
  return false; 
});