import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import NewsImageJa from "images/conoty-lp-top/conoty_top_news_ja.png";
import NewsImageEn from "images/conoty-lp-top/conoty_top_news_en.png";

const NoticeContainer = styled.div`
  ${tw`w-full text-center p-10 bg-gray-100`}
`;

const Title = styled.h2`
  ${tw`text-3xl font-bold text-gray-600 mb-4`}
`;

const NewsImage = styled.img`
  ${tw`w-full h-auto max-w-80 mx-auto `}
`;

const NoticeText = styled.p`
  ${tw`mt-4 text-gray-700 text-base max-w-2xl mx-auto`}
`;

const NoticeLink = styled.a`
  ${tw`block mt-2 text-blue-500 underline`}
`;

const TopNotice = () => {
  const { t, i18n } = useTranslation();
  
  // 言語に応じた画像を選択
  const newsImage = i18n.language === 'ja' ? NewsImageJa : NewsImageEn;

  return (
    <NoticeContainer>
      <Title>NEWS</Title>
      <NewsImage src={newsImage} alt="News" />
      <NoticeText>
        {t('top_notice.description')}
      </NoticeText>
      <NoticeLink href="https://note.com/conoty_official/n/n25d685f05755" target="_blank" rel="noopener noreferrer">
        {t('top_notice.link_text')}
      </NoticeLink>
    </NoticeContainer>
  );
};

export default TopNotice;
